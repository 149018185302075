import React from 'react'
import { qs, qsa } from '../utils/lib'
import _ from 'lodash'
import MiniCart from './minicart.js'
import titleize from 'titleize';

export default class BundleProucts extends React.Component {

    state = {
        product: JSON.parse(this.props.product),
        variants: JSON.parse(this.props.product).variants,
        active_variant: 0,
        options1: [],
        options2: [],
        options3: [],
        option1: 0,
        option2: 0,
        option3: 0,
        cart: new MiniCart(),
        translations: JSON.parse(this.props.translations),
        currentVariant: JSON.parse(this.props.product).variants[0],
    }

    async componentDidMount() {
        this.updateOptions()
        // console.log(this.state.product)
    }


    async getVariant() {
        const f = _.filter(this.state.variants, {
            'option1': this.refs.option1.value,
            'option2': this.refs.option2.value,
            'option3': this.refs.option3.value
        }
        )
        this.setState({
            currentVariant: f[0]
        })
    }

    async updateOptions() {
        const opt1 = _.uniq(_.map(this.state.variants, 'option1'))
        const opt2 = _.uniq(_.map(_.filter(this.state.variants, { 'option1': opt1[this.state.option1] }), 'option2'))
        const opt3 = _.uniq(_.map(_.filter(this.state.variants, { 'option1': opt1[this.state.option1], 'option2': opt2[this.state.option2] }), 'option3'))

        await this.setState({
            options1: opt1,
            options2: opt2,
            options3: opt3
        })
        this.getVariant()

    }



    async setOptions(e) {
        await this.setState({
            option1: e.target.selectedIndex
        })
        this.updateOptions()
    }

    addToCart() {
        let obj = {
            id: this.state.currentVariant.id,
            quantity: 1
        }

        this.postData(this.props.url, obj)
            .then(data => {
                if (typeof gtag !== 'undefined') {
                    gtag('event', 'add_to_cart', {
                        "items": [this.state.currentVariant.google]
                    });
                    gtag('event', 'add', {
                        'event_category': 'cart'
                    });
                }
                this.state.cart.count = data.item_count
                this.state.cart.updateCount()
                this.state.cart.updateCart()
                this.state.cart.open()
            })
    }

    updateCount() {
        qsa('.shopping_cart_count').forEach((el) => {
            el.innerHTML = this.count
            if (this.count == 0) {
                el.classList.add("hidden")
            } else {
                el.classList.remove("hidden")
            }
        })
    }

    async postData(url = '', data = {}) {
        const response = await fetch(url, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content

            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify(data)
        });
        return response.json();
    }

    render() {
        return (
            <div className="product_bundle">
                <h2><a href={this.state.product.url}>{this.state.product.title}</a>
                    <br /> <span className="bold">{this.state.currentVariant.price} EURO.</span></h2>

                <div className="product_bundle_row">
                    <div className="product_bundle_col img">
                        <img loading='lazy' src={this.state.currentVariant.image} alt={this.state.product.title} />
                    </div>
                    <div className={"product_bundle_col bundle_cart_" + this.props.index}>
                        <div className={"product_bundle_option " + (this.state.options1.length == 1 ? "hidden" : "")} >
                            <label htmlFor="">{this.state.product.option1}</label>
                            <select name="" id="" ref="option1" className="option_1" onChange={this.setOptions.bind(this)}>
                                {this.state.options1.map((option) => (
                                    <option key={option} value={option}>{this.state.translations[option]}</option>
                                ))}
                            </select>
                        </div>
                        <div className={"product_bundle_option " + (this.state.options2.length == 1 ? "hidden" : "")} >
                            <label htmlFor="">{this.state.product.option2}</label>
                            <select name="" id="" ref="option2" className="option_2" onChange={this.getVariant.bind(this)}>
                                {this.state.options2.map((option) => (
                                    <option key={option} value={option}>{this.state.translations[option]}</option>
                                ))}
                            </select>
                        </div>
                        <div className={"product_bundle_option " + (this.state.options3.length == 1 ? "hidden" : "")} >
                            <label htmlFor="">{this.state.product.option3}</label>
                            <select name="" id="" ref="option3" className="option_3" onChange={this.getVariant.bind(this)}>
                                {this.state.options3.map((option) => (
                                    <option key={option} value={option}>{this.state.translations[option]}</option>
                                ))}
                            </select>
                        </div>
                        <div className="product_bundle_row">
                            <div className="product_bundle_col">
                                <button className="btn bg-accent" onClick={this.addToCart.bind(this)}>ADD TO CART</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}