import "../../scss/application.scss";
import Inter from "../components/inter.js";
import Scroll from "../components/scrollfix.js";
import MiniCart from "../components/minicart.js";
import Wishlist from "../components/wishlist.js";
import Autocomplete from "../components/autocomplete.js";
import { qs, qsa } from "../utils/lib.js";
import StaticPageNav from "../components/static_page_nav";
import Feather from "feather-icons";
import Siema from "siemajs";
import Style from "../components/map_style";
import BackTop from "../components/backtop";
import defineComponents from "../utils/define";
import BundleProducts from "../components/bundle_products";
import AddressPage from "../components/address";
import Popup from "../components/popup";
import NlPop from "../components/nl_pop";
import HeroVideo from "../components/hero_video";
import MobileMenu from "../components/mobileMenu";
import Carousel from "../components/embla/carousel";
import EmblaCarousel from "embla-carousel";
import { globalAccordion } from "../components/global-accordion";
import { Customizer } from "../components/customizer";
import { filters } from "../components/filters";
import { vhResize } from "../components/vhResize";
import addToCartMobile from "../components/add-to-cart-mobile";
import { SizeGuide } from "../components/size-guide";
import { search } from "../components/search";
import PopupShop from "../components/popupShop";
import { submenu } from "../components/submenu";
import { parallaxAbout, parallaxHero, parallaxProcess } from "../components/parallax";
import { inputRange } from "../components/inputRange";
import { careersScroll } from "../components/careers";
import { gsap } from "gsap";
import videoPlayer from "../components/videoPlayer";
import filmAnchors from "../components/film-anchors";
import filmNav from "../components/film-nav";

window.addEventListener("DOMContentLoaded", () => {
  const careersPage = qs(".js-careers-page");
  if (careersPage) {
    careersScroll();
  }

  const gtag_event = qs('.gtag-event')
  if (gtag_event) {
    gtag_event.addEventListener("click", () => {
      gtag('event', 'submit', {
        'event_category': 'shop',
        'event_label': 'live_chat'
      });
    })
  }

  // if (qs('.vue-configurator')) {
  //   const vueConfigurator = qs('.vue-configurator')

  //   const app = new Vue({
  //     render: h => h(App)
  //   }).$mount()

  //   vueConfigurator.appendChild(app.$el)
  // }


  const map = qs('.store-locator__single')
  if (map) {
    const data = map.dataset
    console.log(data)
    const myLatLng = { lat: parseFloat(data.lat), lng: parseFloat(data.lng) };
    function initMap() {
      // Styles a map in night mode.
      const map = new google.maps.Map(document.getElementById("map"), {
        center: myLatLng,
        zoom: 16,
        styles: Style,
        disableDefaultUI: true
      });

      const mobile_map = new google.maps.Map(document.getElementById("map_mobile"), {
        center: myLatLng,
        zoom: 16,
        styles: Style,
        disableDefaultUI: true
      });

      const m = new google.maps.Marker({
        position: myLatLng,
        icon: "https://atelier-vm.s3.amazonaws.com/uploads/file_uploader/image/8/dot.png"
      });

      const m2 = new google.maps.Marker({
        position: myLatLng,
        icon: "https://atelier-vm.s3.amazonaws.com/uploads/file_uploader/image/8/dot.png"
      });

      m.setMap(map);
      m2.setMap(mobile_map)

    }

    initMap()
  }
  const atc_mobile = new addToCartMobile();
  atc_mobile.init();
  const cycle = qs(".cycle-js");
  if (cycle) {
    let active = 0;
    let slides = cycle.querySelectorAll("p");
    let total = slides.length;
    const assignSlide = () => {
      slides.forEach((slide, i) => {
        if (i == active) {
          slide.classList.add("active");
        } else {
          slide.classList.remove("active");
        }
      });
    };

    assignSlide();
    window.setInterval(() => {
      if (active == total - 1) {
        active = 0;
      } else {
        active++;
      }
      assignSlide();
    }, 6000);
  }

  Feather.replace();
  const show_message = qs(".show_message")
  if (show_message) {
    show_message.addEventListener("click", () => {
      const el = qs('.show_message_body')
      el.classList.toggle('hidden')
    })
  }

  globalAccordion();

  const heroParallax = qs(".landingHeroText");
  parallaxHero();

  const aboutParallaxEl = qs(".js-about-image-offset");
  aboutParallaxEl && parallaxAbout();

  //const processParallaxEl = qs(".js-process-image-offset");
  //processParallaxEl && parallaxProcess();

  //Share link
  const shareLink = qs(".js-share-link");
  if (shareLink) {
    const url = document.location.href;
    shareLink.addEventListener("click", () => {
      navigator.clipboard.writeText(url).then(
        function () {
          gsap.to(qs(".copied"), {
            opacity: 1,
            duration: 0.3,
            onComplete: () => {
              gsap.to(qs(".copied"), {
                opacity: 0,
                duration: 0.3,
                delay: 1,
              });
            },
          });
        },
        function () {
          return;
        }
      );
    });
  }

  const nomadi_car = document.querySelector('.nomadi_car')

  if (nomadi_car) {
    const embla = EmblaCarousel(nomadi_car, {
      align: "start"
    })
  }

  const neutral_slider = document.querySelectorAll('.neutral_slider')
  neutral_slider.forEach((slider) => {
    const embla = EmblaCarousel(slider, {
      loop: false,
      dragFree: false,
      draggable: true,
      skipSnaps: false,
      align: "start",
      containScroll: "trimSnaps",
    })
    const imgs = slider.querySelectorAll("img")
    imgs.forEach((img) => {
      img.addEventListener("load", () => {
        embla.reInit()
      })
    })
  })

  /* Carousel (Embla) */
  const emblaCarousels = Array.from(document.querySelectorAll(".js-embla-slider"));
  if (emblaCarousels) {
    emblaCarousels.forEach((carousel) => {
      const emblaC = new Carousel(carousel, {
        loop: false,
        dragFree: false,
        draggable: true,
        skipSnaps: false,
        align: "start",
        containScroll: "trimSnaps",
      });
      emblaC.init();
    });
  }

  /* Carousel (Embla) */
  const emblaHeroCarousels = Array.from(document.querySelectorAll(".js-embla-hero-slider"));
  if (emblaHeroCarousels) {
    emblaHeroCarousels.forEach((carousel) => {
      const emblaC = new Carousel(carousel, {
        loop: true,
        dragFree: false,
        skipSnaps: false,
        align: "start",

      });
      emblaC.init();
    });
  }


  const emblaCarouselsProdCard = Array.from(document.querySelectorAll(".js-embla-slider-prodCard"));
  if (emblaCarouselsProdCard) {
    emblaCarouselsProdCard.forEach((carousel) => {
      const emblaCProd = new Carousel(carousel, {
        loop: false,
        dragFree: false,
        draggable: true,
        skipSnaps: false,
        align: "start",
        containScroll: "trimSnaps",
      });
      emblaCProd.init();
    });
  }

  const emblaCarouselsProduct = Array.from(document.querySelectorAll(".js-embla-slider-product"));
  if (emblaCarouselsProduct) {
    emblaCarouselsProduct.forEach((carousel) => {
      const emblaProduct = new Carousel(carousel, {
        loop: false,
        dragFree: false,
        draggable: true,
        skipSnaps: false,
        align: "start",
        containScroll: "trimSnaps",
      });
      emblaProduct.init();
    });
  }

  const emblaCarouselsNoLoop = Array.from(document.querySelectorAll(".js-embla-slider-noLoop"));
  if (emblaCarouselsNoLoop) {
    emblaCarouselsNoLoop.forEach((carousel) => {
      const emblaC = new Carousel(carousel, {
        slidesToScroll: 1,
        loop: false,
        skipSnaps: false,
        align: "start",
        containScroll: "trimSnaps",
      });
      emblaC.init();
    });
  }

  //Quote component
  const quoteComponent = Array.from(document.querySelectorAll(".js-embla-quote"));
  if (quoteComponent) {
    quoteComponent.forEach((quoteCarosuel) => {
      const emblaC = new Carousel(
        quoteCarosuel,
        {
          loop: false,
          dragFree: false,
          skipSnaps: false,
          align: "start",
        },
        true
      );
      emblaC.init();
    });
  }
  const multi = qs('.price_multiplier')
  if (multi) {
    multi.addEventListener("change", () => {
      const price = multi.dataset.price
      const delimiter = multi.dataset.delimiter
      const multiplier = multi.value
      const new_price = price * multiplier
      const el = qs('.num_price')
      el.innerHTML = parseFloat(new_price) + delimiter + "00"
    })
  }
  // Old code
  const hero_video = qsa(".js_video");
  hero_video.forEach((el) => {
    new HeroVideo(el);
  })

  const bundle = qs(".header__main--checkout");
  const mc = qs('.minicart_cont')
  if (!bundle && mc) {
    const mc = new MiniCart();
  }
  const wbtn = qsa(".js_wishlist");
  if (wbtn.length > 0) {
    wbtn.forEach((el) => {
      const w = new Wishlist(el);
    });
  }

  const nl = qsa(".js_nl");
  nl.forEach((el) => {
    const popnl = new NlPop(el);
  })


  vhResize();

  new defineComponents(
    {
      ".bundle_products": BundleProducts,
      "#customizer": Customizer,
      "#size-guide": SizeGuide,
    },
    () => { }
  ).load();

  BackTop();
  AddressPage();

  const menu_hamburger = qs(".js_hamburger");
  if (menu_hamburger) {
    const menu_mobile = new MobileMenu();
    menu_mobile.init();
  }

  const popup = qsa(".js-pop");
  popup.forEach((p) => {
    new Popup(p);
  });

  // const carousels = qsa(".js-siema");
  // carousels.forEach((c) => {
  //   const car = new Siema({
  //     selector: c,
  //     duration: 200,
  //     easing: "ease-out",
  //     perPage: c.dataset.per_page ? parseInt(c.dataset.per_page) : 1,
  //     startIndex: 0,
  //     draggable: true,
  //     multipleDrag: true,
  //     threshold: 20,
  //     loop: true,
  //     rtl: false,
  //     onInit: () => {},
  //     onChange: () => {},
  //   });
  //   setInterval(() => car.next(), 3000);
  // });
  const reds = qsa(".redirect_select");
  reds.forEach((red) => {
    red.addEventListener("change", (e) => {
      console.log(red.options);
      location.href = red.options[red.selectedIndex].dataset.redirect;
    });
  });
  const auto = qs(".autocomplete");
  if (auto) {
    const ac = new Autocomplete(auto);
    ac.init();
  }

  new Scroll();
  new Inter();

  //Header
  submenu();
  search();

  const static_page_nav = qs(".js-static-page-nav");
  static_page_nav && StaticPageNav(static_page_nav);

  const filtersEl = qs(".js-filters-bar");
  if (filtersEl) {
    filters();
  }

  inputRange();

  //Shop popup
  if (document.querySelector(".js-popup-shop")) {
    const popupShop = new PopupShop(document.querySelector(".js-popup-shop"));
    popupShop.init();
  }
  const videos = document.querySelectorAll(".film-episode__video")
  videos.forEach((video) => {
    const newVideoPlayer = new videoPlayer(video)
    newVideoPlayer.init()
  })

  if (document.querySelector(".film")) {
    // const newfilmAnchors = new filmAnchors(document.querySelector(".film"))
    //   newfilmAnchors.init()
    const readMore = document.querySelector(".film-intro__readMore")
    const introBody = document.querySelector(".film-intro__body")
    readMore.addEventListener("click", () => {
      gsap.to(introBody, {
        height: "auto",
        duration: .4,
        ease: "Power1.easeInOut"
      })
      gsap.to(readMore, {
        autoAlpha: 0,
        display: "none",
        duration: .2,
        ease: "Power1.easeInOut"
      })
    })
    const newFilmNav = new filmNav(document.querySelector(".film-navigation"))
    newFilmNav.init()
    const videos = document.querySelectorAll(".film-episode__video")
    videos.forEach((video) => {
      const newVideoPlayer = new videoPlayer(video)
      newVideoPlayer.init()
    })





  }

  //Not active code
  //--------------------------------------------------
  // const menu = document.querySelector(".menu_btn");
  // menu.addEventListener("click", () => {
  //   const el = document.querySelector(".mobile_menu");
  //   const banner = document.querySelector(".header_banner");
  //   if (banner) {
  //     banner.classList.toggle("hidden");
  //   }
  //   el.classList.toggle("open");
  // });

  //--------------------------------------------------
  // const scroller = qs(".product_details_images");
  // if (scroller) {
  //   const els = qsa(".product_wrapper", scroller);
  //   const pagers = qsa(".product_details_pager_el");
  //   const is_active = function () {
  //     const trigger = window.innerWidth / 2;
  //     let active = 0;
  //     els.forEach((el, i) => {
  //       const pos = el.getBoundingClientRect();
  //       if (pos.left < trigger && pos.left > -trigger) {
  //         active = i;
  //       }
  //     });
  //     pagers.forEach((el, i) => {
  //       if (i == active) {
  //         el.classList.add("active");
  //       } else {
  //         el.classList.remove("active");
  //       }
  //     });
  //   };
  //   is_active();
  //   scroller.addEventListener("scroll", () => {
  //     is_active();
  //   });
  // }
  //--------------------------------------------------
  // const expanders = qsa(".js_expander");
  // expanders.forEach((btn) => {
  //   btn.addEventListener("click", () => {
  //     console.log(btn, btn.nextElementSibling);
  //     btn.nextElementSibling.classList.toggle("open");
  //   });
  // });


});
