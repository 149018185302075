import { gsap } from "gsap";
import { qs, qsa } from "../utils/lib";

export const submenu = () => {
  const drops = qsa(".js_dropdown");
  const subs = qsa(".header__sub");
  const searchForm = document.querySelector(".search_form");
  const categories = qsa(".header__sub__col1 .js-category");
  const img = qs(".header__sub__col4 .js-category-img");

  drops.forEach(function (drop) {
    drop.addEventListener("click", () => {
      searchForm.classList.remove("active");

      drops.forEach((d, i) => {
        if (d === drop) d.classList.toggle("active_section");
        else d.classList.remove("active_section");
      });

      subs.forEach(function (sub) {
        if (sub != document.querySelector(drop.dataset.target)) {
          sub.classList.remove("open");
        }
      });
      const menu = document.querySelector(drop.dataset.target);
      menu.classList.toggle("open");
    });
  });

  categories.forEach((link, i) => {
    link.addEventListener("mouseenter", () => {
      const tl = gsap.timeline();
      tl.to(img, {
        opacity: 0,
        onComplete: () => {
          img.attributes.src.value = link.dataset.img;
        },
      });
      tl.to(img, {
        opacity: 1,
        delay: 0.25,
      });
    });
  });
};
