import { qs, qsa } from "../utils/lib";
import gsap from "gsap";

export default class Popup {
  constructor(el) {
    this.el = el;
    this.btns = qsa("#" + el.dataset.btn);
    this.close_btn = qs(".js-close-popup", el);
    this.init();
  }

  init() {
    this.btns.forEach((btn, i) => {
      btn.addEventListener("click", () => {
        document.querySelector("html").style.overflow = "hidden";

        gsap.fromTo(
          this.el,
          {
            x: "100%",
          },
          {
            display: "block",

            ease: "power2.out",
            duration: 0.4,
            x: "0%",
          }
        );
      });
    });

    this.close_btn.addEventListener("click", () => {
      document.querySelector("html").style.overflow = "initial";
      gsap.to(this.el, {
        display: "none",
        x: "100%",
        ease: "power2.in",
        duration: 0.4,
      });
    });
  }
}
