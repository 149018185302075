import { qs, qsa } from "../utils/lib";
import gsap from "gsap";

export default class PopupShop {
  constructor(el) {
    this.el = el;
    this.close_btn = qs(".js-close-popup", el);
    this.init();
  }

  init() {
    this.open();
    this.close_btn.addEventListener("click", () => this.close());
  }

  open() {
    gsap.to(this.el, {
      autoAlpha: 1,
      ease: "power2.in",
      duration: 0.4,
      delay: 2,
    });
  }

  close() {
    gsap.to(this.el, {
      autoAlpha: 0,
      display: "none",
      ease: "power2.in",
      duration: 0.4,
    });
  }
}
