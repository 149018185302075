function getVals() {
  const resetBtn = document.querySelector(".reset-button");

  // Get slider values
  let parent = this.parentNode;
  let slides = parent.getElementsByTagName("input");
  let slide1 = parseFloat(slides[0].value);
  let slide2 = parseFloat(slides[1].value);
  // Neither slider will clip the other, so make sure we determine which is larger
  if (slide1 > slide2) {
    let tmp = slide2;
    slide2 = slide1;
    slide1 = tmp;
  }

  let rangeDot1 = document.querySelector(".range-dot1");
  let rangeDot2 = document.querySelector(".range-dot2");

  let rangeText1 = document.querySelector(".range-text1");
  let rangeText2 = document.querySelector(".range-text2");
  rangeText1.value = slide1;
  rangeText2.value = slide2;

  rangeText1.addEventListener("change", () => {
    rangeDot1.value = rangeText1.value;
  });

  rangeText2.addEventListener("change", () => {
    rangeDot2.value = rangeText2.value;
  });

  console.log(rangeText2.max);

  resetBtn.addEventListener("click", () => {
    rangeText1.value = rangeDot1.min;
    rangeText2.value = rangeDot2.max;
    rangeDot1.value = rangeDot1.min;
    rangeDot2.value = rangeDot2.max;
  });
}

export const inputRange = () => {
  let sliderSections = document.getElementsByClassName("range-slider");
  for (let x = 0; x < sliderSections.length; x++) {
    let sliders = sliderSections[x].getElementsByTagName("input");
    for (let y = 0; y < sliders.length; y++) {
      if (sliders[y].type === "range") {
        sliders[y].oninput = getVals;
        // Manually trigger event first time to display values
        sliders[y].oninput();
      }
    }
  }
};
