import gsap from "gsap";

export default class IntersectionObs {
  constructor() {
    this.target = document.querySelectorAll("[data-inter]");
    this.options = {
      threshold: 0.2,
    };
    this.init();
  }

  init() {
    // Set initial state
    this.target.forEach((el) => {
      switch (el.dataset.inter) {
        default:
          gsap.set(el, {
            opacity: 0,
            y: 16,
            duration: 0,
          });
          break;
      }
    });
    this.initObserver();
  }

  animation(entries) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        switch (entry.target.dataset.inter) {
          case "show-image-horizontal":
            gsap.fromTo(
              entry.target,
              {
                opacity: 1,
                clipPath: "polygon(0 0, 0 0, 0 100%, 0% 100%)",
              },
              {
                clipPath: "polygon(0 0, 100% 0, 100% 100%, 0% 100%)",
                ease: "Expo.easeInOut",
                duration: 1.25,
              }
            );
            break;

          case "show-image-vertical":
            gsap.fromTo(
              entry.target,
              {
                opacity: 1,
                clipPath: "polygon(0 0, 100% 0, 100% 0, 0 0)",
              },
              {
                clipPath: "polygon(0 0, 100% 0, 100% 100%, 0% 100%)",
                ease: "Expo.easeInOut",
                duration: 1.25,
              }
            );
            break;

          default:
            gsap.to(entry.target, {
              opacity: 1,
              y: 0,
            });
            break;
        }

        this.observer.unobserve(entry.target);
      }
    });
  }

  initObserver() {
    this.observer = new IntersectionObserver(this.animation.bind(this), this.options);
    this.target.forEach((el) => {
      this.observer.observe(el);
    });
  }
}
