import gsap from "gsap";

export const filters = () => {
  const cont = document.querySelector(".products_filter_cont");
  const actions = document.querySelector("[filter-actions]");
  const button = document.querySelector(".products_filter_btn");

  const prodInGrid = document.querySelector(".js-prodInGrid");
  const prodSingle = document.querySelector(".js-prodSingle");
  const grid = document.querySelector(".products-grid");

  const form = document.querySelector(".js-filters-form");
  const resetBtn = document.querySelector(".reset-button");

  let open = false;
  gsap.to(cont, {
    height: 0,
    duration: 0,
  });
  gsap.to(actions, {
    autoAlpha: 0,
    duration: 0,
  });
  button.addEventListener("click", () => {
    button.classList.toggle("open");
    if (open) {
      open = false;
      gsap.to(cont, {
        height: 0,
      });
      gsap.to(actions, {
        autoAlpha: 0,
      });
    } else {
      open = true;
      gsap.to(cont, {
        height: "auto",
      });
      gsap.to(actions, {
        autoAlpha: 1,
      });
    }
  });

  prodInGrid.addEventListener("click", (e) => {
    prodInGrid.classList.add("active");
    prodSingle.classList.remove("active");
    grid.classList.remove("single");
  });

  prodSingle.addEventListener("click", (e) => {
    prodSingle.classList.add("active");
    prodInGrid.classList.remove("active");
    grid.classList.add("single");
  });


  
};
