import { qs, qsa } from "../utils/lib";
const BackTop = () => {
  const btn = qs(".js-top");
  btn &&
    btn.addEventListener("click", () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    });
};
export default BackTop;
