import EmblaCarousel from "embla-carousel";
import { setupPrevNextBtns, disablePrevNextBtns } from "./prevAndNextButtons";
import { setupDotBtns, generateDotBtns, selectDotBtn } from "./dotButtons";
import Autoplay from "embla-carousel-autoplay";

export default class Carousel {
  constructor(el, options, autoplay = false) {
    this.el = el;
    this.emblaNode = this.el;
    this.options = options;
    this.autoplay = autoplay;
    this.wrap = this.el;
    this.viewPort = this.wrap.querySelector(".embla__viewport");
    this.prevBtn = this.wrap.querySelector(".embla__button--prev");
    this.nextBtn = this.wrap.querySelector(".embla__button--next");
    this.dots = this.el.querySelector(".embla__dots");
  }

  init() {
    const embla = EmblaCarousel(
      this.viewPort,
      { ...this.options },
      this.autoplay && [Autoplay({ delay: 6000 })]
    );
    const dotsArray = generateDotBtns(this.dots, embla);
    const setSelectedDotBtn = selectDotBtn(dotsArray, embla);

    if (this.nextBtn && this.prevBtn) {
      const disablePrevAndNextBtns = disablePrevNextBtns(this.prevBtn, this.nextBtn, embla);
      setupPrevNextBtns(this.prevBtn, this.nextBtn, embla);
      embla.on("select", disablePrevAndNextBtns);
      embla.on("init", disablePrevAndNextBtns);
    }

    setupDotBtns(dotsArray, embla);

    embla.on("select", setSelectedDotBtn);

    embla.on("init", setSelectedDotBtn);
  }
}
