import gsap from "gsap";
import { qs, qsa } from "../utils/lib";

export default class MobileMenu {
  constructor() {
    this.hamburger = qs(".js_hamburger");
    this.menuMobile = qs(".menu-mobile");
    this.close = qs(".menu-mobile__close");
    this.sections = qsa(".menu-mobile__section");
    this.layer = qs('.menu_layer')
  }
  init() {
    this.hamburger.addEventListener("click", () => {
      this.openMenu();
    });
    this.close.addEventListener("click", () => {
      this.closeMenu();
    });

    this.layer.addEventListener("click", () => {
      this.closeMenu();
    });
  }
  openMenu() {
    document.querySelector("html").style.overflow = "hidden";
    this.layer.classList.add("open")
    const tl = gsap.timeline();
    tl.fromTo(
      this.menuMobile,
      {
        x: "-100%",
      },
      {
        display: "block",

        ease: "power2.out",
        duration: 0.3,
        x: "0%",
      }
    );
    tl.fromTo(
      this.sections,
      {
        y: "20%",
        opacity: 0,
      },
      {
        opacity: 1,
        ease: "power2.out",
        duration: 0.3,
        y: "0%",
        stagger: 0.1,
        delay: -0.2,
      },
      "same"
    );
    tl.fromTo(
      this.close,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        ease: "power2.out",
        duration: 0.2,
        delay: -0.2,
      },
      "same"
    );
  }
  closeMenu() {
    this.layer.classList.remove("open")
    document.querySelector("html").style.overflow = "initial";
    gsap.to(this.menuMobile, {
      display: "none",
      ease: "power3.inOut",
      duration: 0.3,
      x: "-100%",
    });
  }
}
