export default class videoPlayer {
    constructor(el) {
        this.el = el
        this.pause = this.el.parentNode.querySelector(".video-pause")
        this.play = this.el.parentNode.querySelector(".video-play")
        this.inner = this.el.parentNode.querySelector('.video-inner')
        this.full = this.el.parentNode.querySelector(".video-fullScreen")
        this.controls = this.el.parentNode.querySelector(".video-controls")
        this.layer = this.el.parentNode.querySelector(".film-episode__initialLayer")
    }

    init() {
        // set the pause button to display:none by default
        this.pause.style.display = "none"

        this.layer.addEventListener("click", () => {
            this.el.play()
            this.play.style.display = "none"
            this.pause.style.display = "block"
            this.controls.classList.add("active")
            this.layer.classList.add("disabled")
            
        })
        
        
        
        // update the progress bar
        this.el.addEventListener("timeupdate", () => {
            let curr = (this.el.currentTime / this.el.duration) * 100
            if(this.el.ended){
               this.play.style.display = "block"
                this.pause.style.display = "none"
            }
            this.inner.style.width = `${curr}%`
        })
        // pause or play the video
        
        this.play.parentNode.addEventListener("click", (e) => {
            this.handlePlay(e)
        })

        this.full.addEventListener("click", (e) => {
            this.fullScreen(e)
        })

        
    }


    handlePlay = (e) => {
        // Condition when to play a video
        if(this.el.paused){
            this.play.style.display = "none"
            this.pause.style.display = "block"
            this.el.play()
        }
        else{
            this.play.style.display = "block"
            this.pause.style.display = "none"
            this.el.pause()
        }
    }
    // trigger fullscreen
    fullScreen = (e) => {
        e.preventDefault()
        this.el.requestFullscreen()
    }
}