import gsap from "gsap";
import { qs, qsa } from "../utils/lib";
import _ from "lodash";

export default class Wishlist {
  constructor(el) {
    this.el = el;
    // this.btn = qs(".js_wishlist");
    this.img = qs("img", this.el);
    this.init();
  }

  init() {
    if (this.el.classList.contains("added")) {
      this.img.src = "/svg/filledfavorite.svg";
    } else {
      this.img.src = "/svg/Heart.svg";
    }

    this.el.addEventListener("click", () => this.toggleFavorite());
  }

  async postData(url = "", data = {}) {
    const response = await fetch(url, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content,
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: JSON.stringify(data),
    });
    return response.json();
  }

  toggleFavorite() {
    let data = {};
    let url = "";
    data.id = this.el.dataset.id;
    if (this.el.classList.contains("added")) {
      this.el.classList.remove("added");
      this.img.src = "/svg/Heart.svg";
      url = this.el.dataset.remove;
    } else {
      this.el.classList.add("added");
      this.img.src = "/svg/filledfavorite.svg";
      qs(".wishlist_counter").classList.add("added");
      url = this.el.dataset.add;
    }
    this.postData(url, data);
  }
}
