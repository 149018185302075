import { qs, qsa } from "../utils/lib";
const AddressPage = () => {
  const options = qsa(".packaging_option");
  const target = qs("#order_packaging_option");
  const address_hid_col = qs(".address-hidden");
  const btn_show_address = qs(".js-open-shipping");
  const btn_show_gift = qs(".js-open-gift");
  const gift_hid_col = qs(".gift-target");
  if (btn_show_address) {
    btn_show_address.addEventListener("click", () => {
      address_hid_col.classList.toggle("hidden");
      btn_show_address.classList.toggle("active");
    });
  }
  if (btn_show_gift) {
    btn_show_gift.addEventListener("click", () => {
      gift_hid_col.classList.toggle("hidden");
      btn_show_gift.classList.toggle("active");
    });
  }
  if (target) {
    let active_option = 0;
    options.forEach((opt, i) => {
      opt.addEventListener("click", () => {
        active_option = i;
        setOptions();
      });
    });
    function setOptions() {
      options.forEach((opt, i) => {
        if (i == active_option) {
          opt.classList.add("selected");
          target.value = opt.dataset.value;
        } else {
          opt.classList.remove("selected");
        }
      });
    }
    setOptions();
  }
};
export default AddressPage;
