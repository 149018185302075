import { qs, qsa } from "../utils/lib";
const StaticPageNav = (el) => {
  let active;
  const items = qsa(".static_page_nav a", el);
  items.forEach((item) => {
    item.addEventListener("click", () => {
      active = item;
      setActive();
    });
  });
  const setActive = () => {
    items.forEach((item) => {
      if (item == active) {
        item.classList.add("active");
      } else {
        item.classList.remove("active");
      }
    });
  };
};
export default StaticPageNav;
