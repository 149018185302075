import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { qs } from "../utils/lib";
gsap.registerPlugin(ScrollToPlugin);

export const careersScroll = () => {
  const aboutButton = qs(".go-to-about");
  const teamButton = qs(".go-to-team");
  const ourValuesButton = qs(".go-to-our-values");
  const careersButton = qs(".go-to-careers");
  const followUsButton = qs(".go-to-follow-us");

  aboutButton.addEventListener("click", () => {
    gsap.to(window, { duration: 0.3, ease: "power3.easeInOut", scrollTo: { y: "#about", offsetY: 100 } });
  });

  teamButton.addEventListener("click", () => {
    gsap.to(window, { duration: 0.3, ease: "power3.easeInOut", scrollTo: { y: "#team", offsetY: 100 } });
  });

  ourValuesButton.addEventListener("click", () => {
    gsap.to(window, {
      duration: 0.3,
      ease: "power3.easeInOut",
      scrollTo: { y: "#our-values", offsetY: 100 },
    });
  });

  careersButton.addEventListener("click", () => {
    gsap.to(window, { duration: 0.3, ease: "power3.easeInOut", scrollTo: { y: "#careers", offsetY: 100 } });
  });

  followUsButton.addEventListener("click", () => {
    gsap.to(window, { duration: 0.3, ease: "power3.easeInOut", scrollTo: { y: "#careers", offsetY: 100 } });
  });
};
