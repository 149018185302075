export default class ScrollFix {
  constructor() {
    this.el = document.querySelector(".js-fix-scroll");
    if (this.el) {
      this.init();
    }
  }

  init() {
    this.calcSizes();
    window.addEventListener("scroll", () => {
      this.calcSizes();
    });
    window.addEventListener("resize", () => {
      this.calcSizes();
    });
  }

  calcSizes() {
    const top = this.el.clientHeight - window.innerHeight;
    if (top > 0) {
      this.el.style.top = -top + "px";
    }
  }
}
