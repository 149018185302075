import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export function parallaxHero() {
  // const el = trigger.querySelector("img")
  //     gsap.to(el, {
  //         yPercent: 50,
  //         ease: "none",
  //         scrollTrigger: {
  //         trigger: trigger,
  //         scrub: true,
  //         },
  //     })

  //  ;

  const elements = Array.from(document.querySelectorAll("[data-parallax]"));
  if (elements) {
    elements.forEach((el) => {
      gsap.set(el, { scale: 1.15 });
    });
    window.addEventListener("scroll", () => {
      window.requestAnimationFrame(parallax);
    });
  }

  function parallax() {
    elements.forEach((el) => {
      const top = el.getBoundingClientRect().top;

      if (top <= window.innerHeight) {
        gsap.to(el, {
          y: -(top / 10),
          ease: "Power1.easeOut",
          duration: 0.2,
        });
      }
    });
  }
}

export function parallaxAbout() {
  const trigger = document.querySelector(".js-about-image-offset");
  const imgs = trigger.querySelectorAll("img");

  let mm = gsap.matchMedia();
  mm.add("(min-width: 800px)", () => {
    gsap.to(imgs[0], {
      yPercent: 10,
      x: 100,
      ease: "none",
      scrollTrigger: {
        trigger: trigger,
        scrub: true,
      },
    });

    gsap.to(imgs[1], {
      yPercent: -30,
      ease: "none",
      scrollTrigger: {
        trigger: trigger,
        scrub: true,
      },
    });
  });
}


export function parallaxProcess() {


  const triggers = document.querySelectorAll(".js-process-image-offset");
  triggers.forEach((trigger, i) => {
    const imgs = trigger.querySelectorAll("img");
    const direction = (i / 2 === 0) ? 1 : -1
    let mm = gsap.matchMedia();
    mm.add("(min-width: 800px)", () => {
      gsap.to(imgs[0], {
        yPercent: 10,
        x: direction * 20,
        ease: "none",
        scrollTrigger: {
          trigger: trigger,
          scrub: true,
        },
      });
  
      // gsap.to(imgs[1], {
      //   yPercent: -30,
      //   ease: "none",
      //   scrollTrigger: {
      //     trigger: trigger,
      //     scrub: true,
      //   },
      // });
    });

  })
  
}