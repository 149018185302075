import { qs, qsa, fetchJSON } from "../utils/lib";
import gsap from "gsap";

export default class HeroVideo {
  constructor(el) {
    this.el = el;
    this.video = el.querySelector("video");
    this.mobile_src = this.video.dataset.mobile;
    this.desk_src = this.video.dataset.video;
    this.isMobile = window.innerWidth < 640;
    this.init();
  }

  init() {
    this.setMobileVideo();
  }

  setMobileVideo() {
    const source = this.video.querySelector("source");
    let new_src;
    if (window.innerWidth < 640) {
      new_src = this.mobile_src;
    } else {
      new_src = this.desk_src;
    }
    this.video.pause();
    source.src = new_src;
    this.video.load();
    this.video.play();
  }
}
