import React, { useEffect, useState } from 'react'
import { globalAccordion } from './global-accordion';

export const Customizer = () => {
    const [activeSlide, setActiveSlide] = useState(-1)

    useEffect(() => {
        setActiveButton()
    })

    const setActiveButton = () => {
        const buttons = Array.from(document.querySelectorAll('.tab__grid button'))

        buttons.forEach((button, i) => {
            button.addEventListener('click', (e) => {
                for (let i = 0; i < buttons.length; i++) {
                    buttons[i].classList.remove('underline')
                }

                e.target.classList.add('underline')
                setActiveSlide(i)
            })
        })
    }

    const renderComponents = (activeSlide) => {
        switch (activeSlide) {
            case 0:
                return <InizialiTab />
                break;
            case 1:
                return <SimboliTab />
                break;
            case 2:
                return <BespokeTab />
                break;
            default:
                return <DefaultTab />
                break;
        }
    }

    return (
        <div>
            <div className="tab__grid">
                <button className="button">Iniziali</button>
                <button className="button">Simboli</button>
                <button className="button">Bespoke</button>
            </div>
            {renderComponents(activeSlide)}
        </div>
    )
}

const DefaultTab = () => {
    useEffect(() => {
        globalAccordion();
    })
    return (
        <>
            <div className="image_wrapper square">
                <img loading='lazy' src="/essenziale/bracciali.jpg" alt="" className="cover" />
            </div>
            <div className="product-accordion-section">
                <Accordion summary="Test"  >
                    <p>Test</p>
                </Accordion>
            </div>
        </>
    )
}

const InizialiTab = () => {
    const [characters, setCharacters] = useState({
        firstChar: 'P',
        secondChar: 'A',
        thirdChar: 'T'
    })

    const changeIniziali = (e, index) => {
        switch (index) {
            case 0:
                setCharacters({ ...characters, firstChar: e.target.value })
                break;
            case 1:
                setCharacters({ ...characters, secondChar: e.target.value })
                break;
            case 2:
                setCharacters({ ...characters, thirdChar: e.target.value })
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        globalAccordion();
    })

    return (
        <>
            <div className="iniziali-tab image_wrapper square bg-lgrey">
                <p className="iniziali">
                    {characters.firstChar}
                    {characters.secondChar}
                    {characters.thirdChar}
                </p>
            </div>
            <div className="iniziali-tab__content">
                <p className="text-12 c-dark">Incisione a macchina</p>
                <p className="text-12 c-rgrey">Questo metodo prevede l’utilizzo di una macchina per incidere le iniziali sul metallo</p>
                <p className="text-12 c-rgrey">45,00€</p>
            </div>
            <div className="iniziali-tab__insert">
                <p className="c-rgrey subtitle">Inserisci le tue iniziali</p>
                <div className="grid">
                    <div className="text-input">
                        <input onChange={(e) => changeIniziali(e, 0)} type="text" name="" id="" maxLength="1" />
                        <p>Prima iniziale</p>
                    </div>
                    <div className="text-input">
                        <input onChange={(e) => changeIniziali(e, 1)} type="text" name="" id="" maxLength="1" />
                        <p>Seconda Iniziale</p>
                    </div>
                    <div className="text-input">
                        <input onChange={(e) => changeIniziali(e, 2)} type="text" name="" id="" maxLength="1" />
                        <p>Terza Iniziale</p>
                    </div>
                </div>
            </div>
            <section className="product-slider">
                <p className="text-11 c-rgrey subtitle">Scegli un carattere</p>
                <div className="product-slider__tracker">
                    <div className="product-slider__col text-center">
                        <div className="iniziali-tab image_wrapper square bg-lgrey">
                            <p className="iniziali">
                                paf
                            </p>
                        </div>
                        <p className='subtitle c-rgrey'>Bodoni</p>
                    </div>
                    <div className="product-slider__col text-center">
                        <div className="iniziali-tab image_wrapper square bg-lgrey">
                            <p className="iniziali">
                                paf
                            </p>
                        </div>
                        <p className='subtitle c-rgrey'>Bodoni</p>
                    </div>
                </div>
            </section>
            <div className="product-accordion-section">
                <Accordion summary="Test"  >
                    <p>Test</p>
                </Accordion>
                <Accordion summary="Test"  >
                    <p>Test</p>
                </Accordion>
                <Accordion summary="Test"  >
                    <p>Test</p>
                </Accordion>
            </div>
        </>
    )
}

const SimboliTab = () => {
    return (
        <div>2</div>
    )
}

const BespokeTab = () => {
    return (
        <div>3</div>
    )
}

const Accordion = ({ summary, children }) => {
    return (
        <>

            <div className="js-accordion product-accordion">
                <button className="product-accordion__summary">
                    <h3 className="product-accordion__title">
                        {summary}
                    </h3>
                    <img loading='lazy' className="product-accordion__arrow" src="/svg/arrow.svg" alt="" />
                </button>
                <div className="product-accordion__details">
                    <div className="product-accordion__inner-content">
                        {children}
                    </div>
                </div>
            </div>
        </>
    )
}