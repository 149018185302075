import gsap from "gsap";
import { qs, qsa } from "../utils/lib.js";

export default class addToCartMobile {
  constructor() {
    this.options_button = qs(".js-options-accordion");
    this.options_details = qs(".product-atc__accordion-detail");
    this.checkout_button = qs(".js-atc-button");
    this.text_col = qs(".product-page__text-col");
    this.clicker = qs(".product-atc__clicker");
  }

  init() {
    if (this.options_button) {
      this.options_button.addEventListener("click", this.handleClicks.bind(this, 1));
      this.clicker.addEventListener("click", this.handleClicks.bind(this, 0));
      window.addEventListener("resize", () => {
        this.text_col.style.zIndex = window.innerWidth > 1024 && "30";
        gsap.to(this.options_details, {
          height: window.innerWidth > 1024 ? "auto" : 0,
          autoAlpha: window.innerWidth > 1024 ? 1 : 0,
          duration: 0,
        });
        gsap.to(this.checkout_button, {
          display: window.innerWidth > 1024 ? "block" : "none",
          duration: 0,
        });
        gsap.to(this.options_button, {
          display: window.innerWidth > 1024 ? "none" : "block",
          duration: 0,
        });
      });

      this.checkout_button.addEventListener("click", () => {
        if (window.innerWidth <= 1024) {
          this.handleClicks(0);
        }
      });
    }
  }

  // direction 1 if opening 0 if closing
  handleClicks = (direction) => {
    // direction ? console.log("true", direction) : console.log("false", direction);
    this.text_col.style.zIndex = direction ? "60" : "30";
    this.clicker.style.pointerEvents = direction ? "all" : "none";
    gsap.to(this.options_details, {
      height: direction ? "auto" : 0,
      autoAlpha: direction ? 1 : 0,
      duration: 0.5,
      ease: "Circ.easeInOut",
    });
    gsap.to(this.checkout_button, {
      display: direction ? "block" : "none",
      duration: 0,
    });
    gsap.to(this.options_button, {
      display: direction ? "none" : "block",
      duration: 0,
    });
  };
}
