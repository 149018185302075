import { qs, qsa, fetchJSON } from "../utils/lib";
import gsap from "gsap";

export default class NlPop {
  constructor(el) {
    this.el = el;
    this.form = qs("form", el);
    this.submit_btn = qs(".js-subscribe");
    this.text = qs(".nl_text", el);
    this.error = qs(".nl_error", el);
    this.email = qs(".nl_email", el);
    this.lang = qs('.nl_language', el)
    this.store = qs('.nl_store', el)
    this.name = qs('.nl_name', el)
    this.close = qs(".js-close-popup", el)
    this.autoload = el.classList.contains("autoload")
    this.timeout = el.dataset.timeout ? el.dataset.timeout : 3000
    this.init();
  }

  init() {
    console.log(this.autoload)
    if (this.autoload) {
      setTimeout(() => {
        this.el.style.display = "flex";
        gsap.to(this.el, {
          opacity: 1
        })
      }, this.timeout)

    }
    if (this.close) {
      console.log('close exists')
      this.close.addEventListener("click", (e) => {
        console.log("trigger close")
        this.closePop()
      })
    }
    if (this.form) {
      this.form.addEventListener("submit", (e) => {
        e.preventDefault();
        this.submit();
      });
    }

  }

  closePop() {
    console.log("function")
    gsap.to(this.el, {
      opacity: 0,
      onComplete: () => {
        this.el.remove()
      }
    })
  }

  submit() {
    let data = { email: this.email.value };
    if (this.name) {
      data.name = this.name.value
      data.store = this.store.value
      data.language = this.lang.value
    }

    fetch(this.form.getAttribute("action"), {
      method: "POST", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
        if (data.response == "ok") {
          if (this.text.dataset.redirect) {
            window.location.href = this.text.dataset.redirect
            return false
          }
          this.text.innerHTML = this.text.dataset.thanks;
          this.form.classList.add("disabled");
          this.error.classList.remove("visible");
        } else {
          if (this.text.dataset.redirect) {
            window.location.href = this.text.dataset.redirect
            return false
          }
          this.error.classList.add("visible");
        }
      })
      .catch((error) => {
        this.error.classList.add("visible");
        console.error("Error:", error);
      });
  }
}
