import React, { useEffect, useState } from 'react'


export const SizeGuide = () => {

    const translations = JSON.parse(document.querySelector(".size_guide_translations").dataset.translations)

    const [activeSlide, setActiveSlide] = useState(-1)

    useEffect(() => {
        setActiveButton()
    })

    console.log(translations)
    const setActiveButton = () => {
        const buttons = Array.from(document.querySelectorAll('.size-guide-popup__buttons button'))

        buttons.forEach((button, i) => {
            button.addEventListener('click', (e) => {
                for (let i = 0; i < buttons.length; i++) {
                    buttons[i].classList.remove('underline')
                }

                e.target.classList.add('underline')
                setActiveSlide(i)
            })
        })
    }

    const renderComponents = (activeSlide) => {
        switch (activeSlide) {
            case 0:
                return <SizeGuideRings translations={translations} />
            case 1:
                return <SizeGuideNecklaces />
            case 2:
                return <SizeGuideBracalets  translations={translations}  />
            default:
                return <SizeGuideRings translations={translations} />
        }
    }

    return (

        <div className="size-guide-popup">
            <h3 className="title">{translations.title.toUpperCase()}</h3>

            <div className="size-guide-popup__buttons">
                <button className="button upcase text-center underline">{translations.anelli}</button>
                <button className="button upcase text-center">{translations.collane}</button>
                <button className="button upcase text-center">{translations.bracciali}</button>
            </div>


            {renderComponents(activeSlide)}

        </div>
    )
}


const SizeGuideRings = ({translations}) => {
    return (
        <>
            <div className="size-guide-popup__content">

               

                <div className="size-guide-popup__container">
                    <div className='size-guide-popup__table'>
                        <table className="pop_table uppercase">
                            <thead>
                                <tr>
                                    <th colSpan="4" title="Field #1">{translations.size}</th>
                                    <th colSpan="2" title="Field #2">{translations.finger}</th>
                                    {/* <th title="Field #3">Taglia</th> */}
                                </tr>
                                <tr>
                                    <td>IT</td>
                                    <td>FR</td>
                                    <td>UK</td>
                                    <td>US</td>
                                    <td>{translations.circ}</td>
                                    <td>{translations.diam}</td>
                                </tr>
                            </thead>

                            {/* Dynamic Body */}
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>41</td>
                                    <td>C</td>
                                    <td>2</td>
                                    <td>40,50-41,40</td>
                                    <td>13.00-13,20</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>42</td>
                                    <td>D</td>
                                    <td>2</td>
                                    <td>41,80-42,40</td>
                                    <td>13,30-13,50</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>43</td>
                                    <td>E</td>
                                    <td>2,5</td>
                                    <td>42,70-43,30</td>
                                    <td>13,60-13,70</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>44</td>
                                    <td>F</td>
                                    <td>3</td>
                                    <td>43,60-44,30</td>
                                    <td>13,80-14,10</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>45</td>
                                    <td>G</td>
                                    <td>3</td>
                                    <td>44,60-45,20</td>
                                    <td>14,20-14,40</td>
                                </tr>
                                <tr>
                                    <td>6</td>
                                    <td>46</td>
                                    <td>H</td>
                                    <td>4</td>
                                    <td>45,60-46,20</td>
                                    <td>14,50-14,70</td>
                                </tr>
                                <tr>
                                    <td>7</td>
                                    <td>47</td>
                                    <td>H + 1/2</td>
                                    <td>4</td>
                                    <td>46,50-47,40</td>
                                    <td>14,80-15,10</td>
                                </tr>
                                <tr>
                                    <td>8</td>
                                    <td>48</td>
                                    <td>I</td>
                                    <td>4</td>
                                    <td>47,80-48,40</td>
                                    <td>15,20-15,40</td>
                                </tr>
                                <tr>
                                    <td>9</td>
                                    <td>49</td>
                                    <td>J</td>
                                    <td>5</td>
                                    <td>48,70-49,30</td>
                                    <td>15,50-15,70</td>
                                </tr>
                                <tr>
                                    <td>10</td>
                                    <td>50</td>
                                    <td>K</td>
                                    <td>5</td>
                                    <td>49,60-50,30</td>
                                    <td>15,80-16,00</td>
                                </tr>
                                <tr>
                                    <td>11</td>
                                    <td>51</td>
                                    <td>L</td>
                                    <td>5,5</td>
                                    <td>50,60-51,20</td>
                                    <td>16,10-16,30</td>
                                </tr>
                                <tr>
                                    <td>12</td>
                                    <td>52</td>
                                    <td>L + 1/2</td>
                                    <td>6</td>
                                    <td>51,50-52,20</td>
                                    <td>16,40-16,60</td>
                                </tr>
                                <tr>
                                    <td>13</td>
                                    <td>53</td>
                                    <td>M</td>
                                    <td>6,5</td>
                                    <td>52,50-53,40</td>
                                    <td>16,70-17,00</td>
                                </tr>
                                <tr>
                                    <td>14</td>
                                    <td>54</td>
                                    <td>N</td>
                                    <td>7</td>
                                    <td>53,70-54,30</td>
                                    <td>17,10-17,30</td>
                                </tr>
                                <tr>
                                    <td>15</td>
                                    <td>55</td>
                                    <td>O</td>
                                    <td>7</td>
                                    <td>54,70-55,30</td>
                                    <td>17,40-17,60</td>
                                </tr>
                                <tr>
                                    <td>16</td>
                                    <td>56</td>
                                    <td>P</td>
                                    <td>8</td>
                                    <td>55,60-56,20</td>
                                    <td>17,70-17,90</td>
                                </tr>
                                <tr>
                                    <td>17</td>
                                    <td>57</td>
                                    <td>P + 1/2</td>
                                    <td>8</td>
                                    <td>56,50-57,20</td>
                                    <td>18,00-18,20</td>
                                </tr>
                                <tr>
                                    <td>18</td>
                                    <td>58</td>
                                    <td>Q + 1/2</td>
                                    <td>8</td>
                                    <td>57,50-58,40</td>
                                    <td>18,30-18,60</td>
                                </tr>
                                <tr>
                                    <td>19</td>
                                    <td>59</td>
                                    <td>R</td>
                                    <td>9</td>
                                    <td>58,70-59,40</td>
                                    <td>18,70-18,90</td>
                                </tr>
                                <tr>
                                    <td>20</td>
                                    <td>60</td>
                                    <td>S</td>
                                    <td>9</td>
                                    <td>59,70-60,30</td>
                                    <td>19,00-19,20</td>
                                </tr>
                                <tr>
                                    <td>21</td>
                                    <td>61</td>
                                    <td>T</td>
                                    <td>9,5</td>
                                    <td>60,60-61,30</td>
                                    <td>19,30-19,50</td>
                                </tr>
                                <tr>
                                    <td>22</td>
                                    <td>62</td>
                                    <td>U</td>
                                    <td>10</td>
                                    <td>61,60-62,20</td>
                                    <td>19,60-19,80</td>
                                </tr>
                                <tr>
                                    <td>23</td>
                                    <td>63</td>
                                    <td>U + 1/2</td>
                                    <td>10</td>
                                    <td>62,50-63,10</td>
                                    <td>19,90-20,10</td>
                                </tr>
                                <tr>
                                    <td>24</td>
                                    <td>64</td>
                                    <td>V</td>
                                    <td>11</td>
                                    <td>63,50-64,40</td>
                                    <td>20,20-20,50</td>
                                </tr>
                                <tr>
                                    <td>25</td>
                                    <td>65</td>
                                    <td>W</td>
                                    <td>11</td>
                                    <td>64,70-65,30</td>
                                    <td>20,60-20,80</td>
                                </tr>
                                <tr>
                                    <td>26</td>
                                    <td>66</td>
                                    <td>X</td>
                                    <td>11</td>
                                    <td>65,70-66,30</td>
                                    <td>20,90-21,10</td>
                                </tr>
                                <tr>
                                    <td>27</td>
                                    <td>67</td>
                                    <td>Y</td>
                                    <td>12</td>
                                    <td>66,30-67,50</td>
                                    <td>21,20-21,50</td>
                                </tr>
                                <tr>
                                    <td>28</td>
                                    <td>68</td>
                                    <td>Z</td>
                                    <td>12</td>
                                    <td>67,90-68,50</td>
                                    <td>21,60-21,80</td>
                                </tr>
                                <tr>
                                    <td>29</td>
                                    <td>69</td>
                                    <td>Z + 1/2</td>
                                    <td>12,5</td>
                                    <td>68,80-69,40</td>
                                    <td>21,90-22,10</td>
                                </tr>
                                <tr>
                                    <td>30</td>
                                    <td>70</td>
                                    <td>1</td>
                                    <td>13</td>
                                    <td>69,70-70,40</td>
                                    <td>22,20-22,40</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="size_guide_flex_cont">
                    <a className='video-link text-center' href={translations.href} target="_blank" rel="noopener noreferrer"><span className="c-rgrey text-11">Download</span></a>

                    <a className='video-link text-center' href="https://www.youtube.com/watch?v=ijs5dKN4HzY&ab_channel=ATELIERVM" target="_blank" rel="noopener noreferrer"><span className="c-rgrey text-11">Video</span> <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-external-link"><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path><polyline points="15 3 21 3 21 9"></polyline><line x1="10" y1="14" x2="21" y2="3"></line></svg></a>
                    </div>
                </div>
            </div>
        </>
    )
}

const SizeGuideNecklaces = () => {
    return (
        <>
            <div className="size-guide-popup__content">
                <div className='size-guide-popup__img-wrapper'>
                    <div className="image_wrapper square">
                        <img loading="lazy" src="/images/size-guide/sg2.jpg" alt="" />
                    </div>
                </div>


                {/* <div className="size-guide-popup__container">
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                </div> */}

            </div>
        </>
    )
}

const SizeGuideBracalets = ({translations}) => {
    return (
        <>
            <div className="size-guide-popup__content">

             
                <div className="size-guide-popup__container">
                    <div className='size-guide-popup__table'>
                        <table className="pop_table uppercase">
                            <thead>
                                <tr className='table-grid'>
                                    <th title="Field #1">{translations.bracelet_length}</th>
                                    <th title="Field #2">{translations.bracelet_size}</th>
                                </tr>
                            </thead>

                            {/* Dynamic Body */}
                            <tbody>
                                <tr className='table-grid'>
                                    <td>16cm</td>
                                    <td >S</td>
                                </tr>
                                <tr className='table-grid'>
                                    <td>17cm</td>
                                    <td >M</td>
                                </tr>
                                <tr className='table-grid'>
                                    <td>18cm</td>
                                    <td >L</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}