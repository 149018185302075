import { qsa } from "../utils/lib";

export const search = () => {
  const drops = qsa(".js_dropdown");
  const subs = qsa(".header__sub");
  const search_btns = qsa(".js-search-btn", document);

  search_btns.forEach((btn) => {
    btn.addEventListener("click", () => {
      drops.forEach((d, i) => {
        d.classList.remove("active_section");
      });

      subs.forEach(function (sub) {
        sub.classList.remove("open");
      });

      let form;
      form = document.querySelector(".search_form");

      form.classList.toggle("active");

      const input = form.querySelector("input");
      input && input.focus();
    });
  });
};
