import gsap from "gsap/all";

export default class filmNav {
    constructor(el) {
        this.el = el
        this.details = this.el.querySelector(".film-navigation__main")
        this.button = this.el.querySelector(".film-navigation__menuMobile")
       
    }
    init() {
      
        // window.addEventListener("resize")
        // this.button.addEventListener("click", (e) => {
        //     this.button.classList.toggle("open");

        //     if (this.button.classList.contains("open")) {
        //         gsap.to(this.details, {
        //           duration: 0.64,
        //           height: "auto",
        //         });
        //       } else {
        //         gsap.to(this.details, {
        //           duration: 0.64,
        //           height: 0,
        //         });
        //       }
        // })
    }
}